import * as React from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { hasValue, reorderArray } from '@digital-magic/ts-common-utils'
import { CreateLayoutTypeOptionValuesRequest } from '@api/endpoints/buildings/layoutTypes'
import { OptionId, useGetOptionValues } from '@api/endpoints/buildings/options'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import classNames from 'classnames'
import { DragIndicator } from '@mui/icons-material'
import { Alert, Box, CircularProgress } from '@mui/material'
import { DragDropContext, Draggable, DropResult, Droppable } from '@hello-pangea/dnd'
import { Button } from '@controls/Button'
import { useFormContextTyped } from '@controls/Form'
import { OptionValueItemForm } from '@pages/Admin/LayoutTypes/LayoutTypeForm/LayoutTypeOptions/LayoutTypeOptionForm/OptionValueItems/OptionValueItemForm'
import { OptionValueItemsStyled } from './OptionValueItems.styles'

const FormValues = CreateLayoutTypeOptionValuesRequest.extend({
  optionId: OptionId
})
type FormValues = z.infer<typeof FormValues>

type Props = {
  isParentLoading: boolean
  editDisabled: boolean
  deleteDisabled: boolean
}

export const OptionValueItems: React.FC<Props> = ({ isParentLoading, editDisabled, deleteDisabled }) => {
  const { t } = useTranslation()
  const form = useFormContextTyped<FormValues>()
  const defaultErrorHandler = useAdminDefaultErrorHandler()

  const optionId = useWatch({ control: form.control, name: 'optionId' })

  const getOptionValues = useGetOptionValues(optionId, { onError: defaultErrorHandler, enabled: hasValue(optionId) })

  const isLoading = isParentLoading || getOptionValues.isFetching

  const formOptionValues = useFieldArray({ control: form.control, name: 'options' })

  const watchedOptionValueIds = useWatch({
    control: form.control,
    name: Array.from({ length: formOptionValues.fields.length }).map((_, i) => `options.${i}.optionValueId` as const)
  })

  const onAddOptionValue = async (): Promise<void> => {
    if (await form.trigger('options')) {
      // TODO: Write a correct code here to meet type
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formOptionValues.append({ valueOrderNumber: formOptionValues.fields.length })
    }
  }

  const onRemoveOptionValue =
    (index: number) =>
    (confirmResult: boolean): void => {
      if (confirmResult) {
        formOptionValues.remove(index)
      }
    }

  const isOptionValueAddingDisabled =
    !hasValue(optionId) || formOptionValues.fields.length >= (getOptionValues.data?.length || 0)

  const onDragEnd = (result: DropResult): void => {
    if (
      hasValue(result.destination) &&
      hasValue(result.source.index) &&
      result.destination.index !== result.source.index
    ) {
      const reordered = reorderArray(form.getValues().options, result.source.index, result.destination.index)
      form.setValue(
        'options',
        reordered.map((f, i) => ({
          optionValueId: f.optionValueId,
          price: f.price,
          valueOrderNumber: i
        }))
      )
    }
  }

  return (
    <OptionValueItemsStyled>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-option-values-list">
            {(provided) => (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                rowGap={2}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {formOptionValues.fields.map((f, i) => (
                  <Draggable key={f.id} draggableId={f.id} index={i}>
                    {(provided, snapshot) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        columnGap={4}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={classNames({ 'draggable-item': true, dragging: snapshot.isDragging })}
                      >
                        <OptionValueItemForm
                          index={i}
                          optionValueId={watchedOptionValueIds[i]}
                          availableOptionValues={getOptionValues.data ?? []}
                          isParentLoading={isLoading}
                          editDisabled={editDisabled}
                          deleteDisabled={deleteDisabled}
                          onRemoveOptionValue={onRemoveOptionValue(i)}
                        />
                        <div {...provided.dragHandleProps}>
                          <DragIndicator />
                        </div>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {formOptionValues.fields.length == 0 && (
        <Alert severity="error">{t('pages.admin.layout_type.options.form.messages.must_select_value')}</Alert>
      )}
      {!isOptionValueAddingDisabled && (
        <Button color="brown" type="button" disabled={isLoading} onClick={onAddOptionValue}>
          {t('pages.admin.layout_type.option_values.buttons.add')}
        </Button>
      )}
    </OptionValueItemsStyled>
  )
}
