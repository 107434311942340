import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { OptionalType, hasValue } from '@digital-magic/ts-common-utils'
import {
  CreateLayoutTypeOptionValuesRequest as CreateLayoutTypeOptionValuesRequest1,
  LayoutTypeId,
  LayoutTypeOptionView
} from '@api/endpoints/buildings/layoutTypes'
import {
  useCreateLayoutTypeOptionValues,
  useDeleteLayoutTypeOptionValues,
  useUpdateLayoutTypeOptionValues
} from '@api/endpoints/buildings/layoutTypes/api'
import { OptionId, OptionList } from '@api/endpoints/buildings/options'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { DragIndicator } from '@mui/icons-material'
import { Box } from '@mui/material'
import { DraggableProvidedDragHandleProps } from '@hello-pangea/dnd'
import { Button } from '@controls/Button'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { Form, FormSelectField, useFormTyped } from '@controls/Form'
import { MenuItemEntry } from '@controls/types'
import { useLayoutTypeFormContext } from '../../LayoutTypeFormContext'
import { LayoutTypeOptionFormStyled } from './LayoutTypeOptionForm.styles'
import { OptionValueItems } from './OptionValueItems'

const FormValues = CreateLayoutTypeOptionValuesRequest1.extend({
  optionId: OptionId,
  layoutTypeId: LayoutTypeId
})
type FormValues = z.infer<typeof FormValues>

type LayoutTypeOptionFormProps = {
  option?: LayoutTypeOptionView
  availableOptions: OptionList
  dragHandleProps?: DraggableProvidedDragHandleProps | null
  isDragging?: boolean
  isParentLoading: boolean
}

export const LayoutTypeOptionForm: React.FC<LayoutTypeOptionFormProps> = ({
  option,
  availableOptions,
  dragHandleProps,
  isDragging,
  isParentLoading
}) => {
  const { t } = useTranslation()
  const { layoutType } = useLayoutTypeFormContext()
  const defaultErrorHandler = useAdminDefaultErrorHandler()

  const layoutTypeId = layoutType.id

  const createOption = useCreateLayoutTypeOptionValues({ onError: defaultErrorHandler })
  const updateOption = useUpdateLayoutTypeOptionValues({ onError: defaultErrorHandler })
  const deleteOption = useDeleteLayoutTypeOptionValues({ onError: defaultErrorHandler })

  const isLoading = isParentLoading || createOption.isLoading || updateOption.isLoading || deleteOption.isLoading

  const selectOptions: ReadonlyArray<MenuItemEntry<OptionId>> = React.useMemo(() => {
    const selectedOption: OptionalType<MenuItemEntry<OptionId>> = hasValue(option)
      ? { label: `${option.code}: ${option.name.eng}`, value: option.optionId }
      : undefined
    const options = availableOptions
      .concat()
      //.filter((opt) => option?.optionId === opt.id)
      .sort((a, b) => a.code.localeCompare(b.code))
      .map((o) => ({ label: `${o.code}: ${o.name.eng}`, value: o.id }))

    return hasValue(selectedOption)
      ? [selectedOption].concat(options).filter((opt) => selectedOption.value === opt.value)
      : options
  }, [option, availableOptions])

  const onSubmit = (values: FormValues): void => {
    if (option) {
      updateOption.mutate(values)
    } else {
      createOption.mutate(values)
    }
  }

  const onDeleteOption = (confirmResult: boolean): void => {
    if (option && confirmResult) {
      deleteOption.mutate({ layoutTypeId, optionId: option.optionId })
    }
  }

  const defaultOptionValues: FormValues['options'] = React.useMemo(
    () =>
      option?.values
        .map((o) => ({
          price: Number(o.price),
          optionValueId: o.optionValueId,
          valueOrderNumber: o.orderNumber
        }))
        .sort((a, b) => a.valueOrderNumber - b.valueOrderNumber) ?? [],
    [option]
  )

  const form = useFormTyped({
    resolver: FormValues,
    onSubmit,
    defaultValues: {
      optionId: option?.optionId,
      layoutTypeId,
      options: defaultOptionValues as Array<never>
    }
  })

  const optionId = form.watch('optionId')
  const options = form.watch('options')

  React.useEffect(() => {
    if (hasValue(optionId)) {
      form.resetField('options', { defaultValue: defaultOptionValues })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionId, defaultOptionValues])

  const isFormEditDisabled = false
  //useMemo(() => !isEditableEntity(layoutType.allowedActions), [layoutType])
  const isOptionSelectDisabled = hasValue(option) || isFormEditDisabled

  return selectOptions.length == 0 ? null : (
    <LayoutTypeOptionFormStyled isDragging={isDragging ?? false}>
      <Form f={form}>
        <Box display="flex" flexDirection="column" width="100%" rowGap={2}>
          <Box display="flex" justifyContent="center" alignItems="center">
            {hasValue(option) && (
              <div {...dragHandleProps}>
                <DragIndicator />
              </div>
            )}
            <FormSelectField
              options={selectOptions}
              name={form.names.optionId}
              label={t('pages.admin.layout_type.options.form.field.option')}
              disabled={isLoading || isOptionSelectDisabled}
              fullWidth
            />
          </Box>
          {/* in both cases passing edit disabled because LayoutTypeOptions are nested and their deletion should be prevented only if whole LayoutType is not editable */}
          {hasValue(optionId) && (
            <OptionValueItems
              isParentLoading={isLoading}
              editDisabled={isFormEditDisabled}
              deleteDisabled={isFormEditDisabled}
            />
          )}
          <Box display="flex" columnGap={2}>
            <Button type="submit" disabled={isLoading || isFormEditDisabled || options.length == 0}>
              {option
                ? t('pages.admin.layout_type.options.buttons.edit')
                : t('pages.admin.layout_type.options.buttons.add')}
            </Button>
            {option && (
              <ButtonWithConfirmation
                color="error"
                // LayoutTypeOptions are nested and their deletion should be prevented only if whole LayoutType is not editable
                disabled={isLoading || isFormEditDisabled}
                onConfirmResult={onDeleteOption}
                confirmTitle={t('global.consent.delete.title')}
                confirmMessage={t('global.consent.delete.message')}
              >
                {t('pages.admin.layout_type.options.buttons.delete')}
              </ButtonWithConfirmation>
            )}
          </Box>
        </Box>
      </Form>
    </LayoutTypeOptionFormStyled>
  )
}
