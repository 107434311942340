import styled, { css } from 'styled-components'

export const OptionValueItemsStyled = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing(2)};

    .draggable-item.dragging {
      background-color: ${theme.colors.grey600};
    }
  `
)
