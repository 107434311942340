import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { hasValue, reorderArray } from '@digital-magic/ts-common-utils'
import { LayoutTypeView, useUpdateLayoutTypeOptionsOrder } from '@api/endpoints/buildings/layoutTypes'
import { useGetOptions } from '@api/endpoints/buildings/options'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { Box } from '@mui/material'
import { DragDropContext, Draggable, DropResult, Droppable } from '@hello-pangea/dnd'
import { Text } from '@controls/Text'
import { useLayoutTypeFormContext } from '../LayoutTypeFormContext'
import { LayoutTypeOptionForm } from './LayoutTypeOptionForm'
import { LayoutTypeOptionsStyled } from './LayoutTypeOptions.styles'

const getSortedOptions: (options: LayoutTypeView['options']) => LayoutTypeView['options'] = (options) =>
  options
    .concat()
    .sort((a, b) => a.orderNumber - b.orderNumber)
    .map((o) => ({
      ...o,
      values: o.values.concat().sort((a, b) => a.orderNumber - b.orderNumber)
    }))

export const LayoutTypeOptions: React.FC = () => {
  const { t } = useTranslation()
  const { layoutType } = useLayoutTypeFormContext()
  const defaultErrorHandler = useAdminDefaultErrorHandler()
  const [options, setOptions] = React.useState(getSortedOptions(layoutType.options))

  const getOptions = useGetOptions(undefined, { onError: defaultErrorHandler })
  const updateOptionsOrder = useUpdateLayoutTypeOptionsOrder({ onError: defaultErrorHandler })

  const isLoading = getOptions.isFetching || updateOptionsOrder.isLoading

  const availableOptions = React.useMemo(
    () =>
      (getOptions.data ?? []).filter(
        (opt) =>
          !layoutType.options.some((existingOption) => opt.id === existingOption.optionId) &&
          !layoutType.freeOptions.some((freeOption) => opt.id === freeOption.optionId)
      ),
    [getOptions.data, layoutType.options, layoutType.freeOptions]
  )

  const onDragEnd = (result: DropResult): void => {
    if (
      hasValue(result.destination) &&
      hasValue(result.source.index) &&
      result.destination.index !== result.source.index
    ) {
      const reordered = reorderArray(options, result.source.index, result.destination.index).map((v, i) => ({
        ...v,
        orderNumber: i
      }))

      setOptions(reordered)
      updateOptionsOrder.mutate({
        layoutTypeId: layoutType.id,
        items: reordered.map((o) => ({
          item: o.optionId,
          index: o.orderNumber
        }))
      })
    }
  }

  React.useEffect(() => {
    const options = getSortedOptions(layoutType.options)
    setOptions(options)
  }, [layoutType.options])

  return (
    <LayoutTypeOptionsStyled>
      <Text component="div" size="size-24" weight="regular" mb={2}>
        {t('pages.admin.layout_type.options.title')}
      </Text>
      {availableOptions.length === 0 && options.length === 0 ? (
        <Text component="div" weight="regular" mt={2} size="size-18">
          {t('pages.admin.layout_type.options.messages.nothing_to_add')}
        </Text>
      ) : (
        <Box display="flex" flexDirection="column" rowGap={4}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-options-list">
              {(provided) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  rowGap={4}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {options.map((o, i) => (
                    <Draggable key={o.optionId} draggableId={o.optionId} index={i}>
                      {(provided, snapshot) => (
                        <div
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          // {...provided.dragHandleProps}
                        >
                          <LayoutTypeOptionForm
                            availableOptions={availableOptions}
                            option={o}
                            isParentLoading={isLoading}
                            dragHandleProps={provided.dragHandleProps}
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            isDragging={snapshot.isDragging}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
          {availableOptions.length > 0 && (
            <LayoutTypeOptionForm
              availableOptions={availableOptions}
              key={options.length}
              isParentLoading={isLoading}
            />
          )}
        </Box>
      )}
    </LayoutTypeOptionsStyled>
  )
}
