import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CreateLayoutTypeOptionValuesRequest } from '@api/endpoints/buildings/layoutTypes'
import { OptionValueId, OptionValueList } from '@api/endpoints/buildings/options'
import { Delete } from '@mui/icons-material'
import { Box } from '@mui/material'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { FormSelectField, FormTextField, useFormContextTyped } from '@controls/Form'
import { MenuItemEntry } from '@controls/types'

type Props = {
  index: number
  optionValueId: OptionValueId
  availableOptionValues: OptionValueList
  isParentLoading: boolean
  editDisabled: boolean
  deleteDisabled: boolean
  onRemoveOptionValue: (confirmResult: boolean) => void
}

export const OptionValueItemForm: React.FC<Props> = ({
  index,
  optionValueId,
  availableOptionValues,
  isParentLoading,
  editDisabled,
  deleteDisabled,
  onRemoveOptionValue
}) => {
  const { t } = useTranslation()
  const form = useFormContextTyped<CreateLayoutTypeOptionValuesRequest>()

  const selectOptions: ReadonlyArray<MenuItemEntry<OptionValueId>> = React.useMemo(() => {
    const values = form.getValues()
    //const selected = availableOptionValues.find(v => v.id === optionValueId)

    return availableOptionValues
      .filter((v) => v.id == optionValueId || !values.options.some((ov) => v.id === ov.optionValueId))
      .map((o) => ({ label: o.name.eng, value: o.id }))
  }, [availableOptionValues, optionValueId, form])

  return (
    <>
      <ButtonWithConfirmation
        color="error"
        disabled={isParentLoading || deleteDisabled}
        onConfirmResult={onRemoveOptionValue}
        confirmTitle={t('global.consent.delete.title')}
        confirmMessage={t('global.consent.delete.message')}
      >
        <Delete />
      </ButtonWithConfirmation>
      <Box flex={1}>
        <FormSelectField
          options={selectOptions}
          name={form.names.options[index].optionValueId}
          label={t('pages.admin.layout_type.option_values.form.field.option_value')}
          disabled={isParentLoading || editDisabled}
          fullWidth
        />
      </Box>
      <Box flex={1}>
        <FormTextField
          type="number"
          name={form.names.options[index].price}
          label={t('pages.admin.layout_type.option_values.form.field.price')}
          disabled={isParentLoading || editDisabled}
          fullWidth
        />
      </Box>
    </>
  )
}
