import styled, { css } from 'styled-components'

export const LayoutTypeOptionsStyled = styled('div')(
  ({ theme }) => css`
    padding: ${theme.spacing(4, 0)};

    form {
      display: flex;
      align-items: center;
      column-gap: ${theme.spacing(4)};

      button {
        white-space: nowrap;
      }
    }

    ul {
      padding: 0;

      li {
        margin: ${theme.spacing(1, 0)};
        display: flex;
        align-items: center;
        column-gap: ${theme.spacing(2)};
      }
    }
  `
)
